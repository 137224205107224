var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("div", { staticClass: "grid-column__header" }, [_vm._t("header")], 2),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.resizeable
        ? _c(
            "ClientOnly",
            [
              _c("GridColumnResizer", {
                attrs: {
                  position: _vm.resizerPosition,
                  "parent-reference": _vm.$el
                },
                on: { "width-change": _vm.onUpdateWidth, resize: _vm.onResize }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }