var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridActionColumn",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("GridTableCell", {
                attrs: { locked: true, row: _vm.rowsOffset, column: 0 }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.isBasicFilter
        ? _c("GridTableCell", {
            attrs: { locked: true, row: _vm.rowsOffset + 1, column: 0 }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.dataCount, function(row, rowIndex) {
        return _c("GridSelectRowEditCell", {
          key: row,
          attrs: {
            column: 0,
            disabled: _vm.disabledRows[_vm.rowIds[rowIndex]],
            row: _vm.rowsOffset + row + _vm.basicFiltersOffset,
            "row-id": _vm.rowIds[rowIndex],
            selected: _vm.selectedRows[_vm.rowIds[rowIndex]]
          },
          on: { select: _vm.onSelectRow }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }