var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GridActionColumn",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c("GridTableCell", {
                attrs: {
                  row: _vm.rowsOffset,
                  column: _vm.columnsOffset,
                  locked: true
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _vm.isBasicFilter
        ? _c("GridTableCell", {
            attrs: {
              row: _vm.rowsOffset + _vm.basicFiltersOffset,
              column: _vm.columnsOffset,
              locked: true
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.rows, function(row, rowIndex) {
        return _c("GridActionCell", {
          key: _vm.rowIds[rowIndex] + "|" + _vm.column.id,
          attrs: {
            component: _vm.actionCellComponents[_vm.column.id],
            "column-index": _vm.columnsOffset,
            column: _vm.column,
            "row-id": _vm.rowIds[rowIndex],
            action: row._links.value[_vm.column.id],
            "row-index": _vm.rowsOffset + rowIndex + _vm.basicFiltersOffset + 1,
            disabled: _vm.disabledRows[_vm.rowIds[rowIndex]],
            selected: _vm.getSelectedRowState(rowIndex)
          },
          on: { action: _vm.onRowAction }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }