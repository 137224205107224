var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "IntersectionObserver",
    {
      attrs: { options: _vm.observerOptions },
      on: { intersect: _vm.onIntersect }
    },
    [_c("div", { class: _vm.classes })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }